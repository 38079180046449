<template>
    <audio v-for="(file, index) in audioFiles" :key="'mp3file_'+index" :ref="file" :src="'/audio/'+file+'.mp3'"></audio>
  <div class="home" style="margin:auto; text-align: center;">
    <h1>Janne's Ideogram practice</h1>
    <hr>
    <button :disabled="isClicked(word)" v-for="(word, index) in words" :key="'mp3button_'+index"  @click="playOnce(word)" >{{ word }}</button>
    <hr>
    <table style="margin-left:auto; margin-right:auto">
        <tr>
            <td>
                <p>Voice</p>
    <select :disabled="isDisabled" v-model="voice">
      <option  v-for="voice in voices" :key="voice" :value="voice">{{voice}}</option>
    </select>
            </td>
            <td>
                <p>Delay</p>
    <input :disabled="isDisabled" type="range" v-model="delay" min="0.5" max="10" step="0.1">
    <p>{{delay}}s</p>
            </td>
            <td>
                <button :disabled="isDisabled" @click="start">Start</button>
            </td>
            <td>   <button @click="stop">Stop</button></td>
        </tr>
    </table>
  </div>
  <div class="center-text" style="padding-top: 10%; text-align: center; font-size:4rem; text-transform: uppercase;">{{currentWord}}</div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
        clicked: [],
        currentWord: 'land',
        voice: 'joey',
        word: [],
        voices: ['joey','salli'],
        lastWord: '',
        words: ['land', 'water','space','motion','energy','manmade','natural','life'],
        speed: 1,
        delay: 3,
        loop: false,
        wakeLock: null,
    }
  },
  computed: {
    isAllClicked() {
      return this.words.every(word => this.clicked.includes(word));
    },
    isDisabled() {
        if(!this.loop) {
            return false;
        }
      return true;
    },
    audioFiles() {
        let files = [];
        this.voices.forEach(voice => {
            this.words.forEach(word => {
                files.push(voice + '_' + word);
            });
        });
      return files
    }
  },
  mounted() {
    this.requestWakeLock();
    console.log(this.audioFiles);
  },
  beforeDestroy() {
    this.releaseWakeLock();
  },
  methods: {
    isClicked(word) {
      return this.clicked.includes(word);
    },
    word() {

    },
    stop() {
        clearInterval(this.loop);
        this.loop = false;
    },
    playOnce(word) {
        this.$refs[this.voice+'_'+word][0].play();
        this.currentWord = word;
        this.clicked.push(word);
    },
    start() {
        this.loop = setInterval(() => {
            while(this.currentWord === this.lastWord) {
                const randomElement = this.words[Math.floor(Math.random() * this.words.length)];
                this.currentWord = randomElement;
            }
            this.lastWord = this.currentWord;
            this.$refs[this.voice+'_'+this.currentWord][0].play();
        }, this.delay * 1000);
    },
    async requestWakeLock() {
      if ('wakeLock' in navigator) {
        try {
          this.wakeLock = await navigator.wakeLock.request('screen');
          console.log('Screen Wake Lock is active');
          
          // Handle visibility change
          document.addEventListener('visibilitychange', this.handleVisibilityChange);
        } catch (err) {
          console.error(`Could not obtain wake lock: ${err.name}, ${err.message}`);
        }
      } else {
        console.log('Screen Wake Lock API is not supported in this browser.');
      }
    },
    async releaseWakeLock() {
      if (this.wakeLock !== null) {
        try {
          await this.wakeLock.release();
          this.wakeLock = null;
          console.log('Screen Wake Lock was released');
          document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        } catch (err) {
          console.error(`Could not release wake lock: ${err.name}, ${err.message}`);
        }
      }
    },
    handleVisibilityChange() {
      if (document.visibilityState === 'visible' && this.wakeLock === null) {
        this.requestWakeLock();
      }
    },
    toggleWakeLock() {
      if (this.wakeLock) {
        this.releaseWakeLock();
      } else {
        this.requestWakeLock();
      }
    }
  },
}
</script>
<style>
html, body {
    height: 100%;
    margin: 0;
}
td {
    padding: 10px;
}
.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}
</style>
